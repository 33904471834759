import React from 'react'
//import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import SubPageImgHero from '../../components/subPageImgHero'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
  margin: 25px auto;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

const Firehjulskontroll = () => {
  const title = 'Hjulstillingskontroll'
  const Img = require('../../images/_02.png')
  return (
    <div>
      <SubPageLayoutNoHero>
        <SEO title={title} />
        <SubPageImgHero title={title} Img={Img}></SubPageImgHero>
        <PageSection>
          <Text>
            <p>
              Riktig hjulstilling på alle fire hjul er viktig for å ikke slite dekkene skjevt, og
              ikke minst er det drivstoffbesparende. Vi måler og kontrollerer hjulstillingen på
              bilen, og bytter alt av hjuloppheng, foringer, endeledd, kuler, støtdempere og fjærer
              ved behov. Ønsker du forstillingskontroll eller firehjulskontroll fikser vi selvsagt
              også dette, i tillegg til å gjøre eventuelle justeringer på forstillingen.
            </p>
            <h4>Kvalitet på deler</h4>
            <p>
              Hos BilXtra Verksted opererer vi utelukkende med bildeler av OE-kvalitet eller
              Matching Quality. Det vil si at alle våre deler er av minst like god kvalitet som
              originalmontert deler. Lar du oss fikse bilen din, kan du derfor være trygg på at du
              får topp kvalitet!
            </p>
            <ChooseRegion />
          </Text>
        </PageSection>
      </SubPageLayoutNoHero>
    </div>
  )
}

export default Firehjulskontroll
